<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    v-if="item.title != 'OTP Usage' || admin_profile.brand_trees_id == null"
    :active-class="`primary white--text`"
  >
    <v-list-item-icon v-if="item.icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
    <v-list-item-action v-if="item.title == 'Shipments' && shipment > 0">
      <v-chip color="red" small dark>{{ shipment }}</v-chip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";
import { mapState } from "vuex";

export default {
  name: "BaseItem",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    shipment: 0,
  }),
  computed: {
    ...mapState("login", ["admin_profile"]),

    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
  created() {
    setTimeout(() => {
      this.shipment = JSON.parse(localStorage.getItem("noti")).shipment;
    }, 100);
  },
};
</script>
